"use client";

import { DASHBOARD_PAGE_URL_SLUG, DASHBOARD_PAST_INVESTMENTS_URL_SLUG } from "@common/constants";
import { ROUTE_PARAM_SLUGS } from "@common/constants/urlParam";
import { inter, lora, spaceGrotesk, notoSerif, notoSans, bricolageGrotesque } from "@common/theme/fonts";
import { isUndefinedOrNull } from "@common/utils/variable";
import { useParams, usePathname } from "next/navigation";
import { useMemo } from "react";
import { Interpolation } from "styled-components";
const HtmlWrapper = ({
  isDesktop,
  children
}: {
  isDesktop: boolean;
  children: React.ReactNode;
}) => {
  const pathname = usePathname();
  const params = useParams();
  const bodyStyles = useMemo(() => {
    let styles: Interpolation<React.CSSProperties> = {
      overflow: "auto",
      overscrollBehaviorY: "auto"
    };
    const isDashboardDetailsPage = !isUndefinedOrNull(params[ROUTE_PARAM_SLUGS.DASHBOARD.DETAILS_PAGE]);

    /**
     * Checking if dashboard main page
     * true for dashboard main landing page (/dashboard/<product>/) and false for dashboard details page and past investments page
     */
    const isDashboardMainPage = pathname.includes(DASHBOARD_PAGE_URL_SLUG) && !isDashboardDetailsPage && !pathname.includes(DASHBOARD_PAST_INVESTMENTS_URL_SLUG);

    /**
     * Adding overflow hidden on dashboard page
     * true for mobile and false for desktop && for dashboard main landing page
     * why? -> to prevent body scroll on mobile in intial load when css is yet to be set through useEffect
     */
    const dashboardOverflowCondition = !isDesktop && isDashboardMainPage;

    // adding overflow hidden by default on condition
    if (dashboardOverflowCondition) {
      styles = {
        ...styles,
        overflow: "hidden"
      };
    }

    /**
     * Adding overscroll behavior none on dashboard page
     * true for desktop and false for mobile && for dashboard main landing page
     * why? -> main page repayments has upcoming paid as fixed, and when overscroll happens since its fixed, it stays fixed but the page moves and it looks weird ( like it shows a gap )
     */
    const dashboardOverScrollBehvaiorCondition = isDesktop && isDashboardMainPage;
    if (dashboardOverScrollBehvaiorCondition) {
      styles = {
        ...styles,
        overscrollBehaviorY: "none"
      };
    }
    return styles;
  }, [isDesktop, params, pathname]);
  return <html lang="en" data-sentry-component="HtmlWrapper" data-sentry-source-file="HtmlWrapper.tsx">
			<body className={`${inter.className} ${inter.variable} ${lora.variable} ${spaceGrotesk.variable} ${notoSans.variable} ${notoSerif.variable} ${bricolageGrotesque.variable} `} style={bodyStyles}>
				{children}
			</body>
		</html>;
};
export default HtmlWrapper;