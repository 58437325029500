"use client";

import { useState } from "react";
import { useServerInsertedHTML } from "next/navigation";
import { ServerStyleSheet, StyleSheetManager } from "styled-components";
import { isBrowser } from "@common/utils/browser";
export default function StyledComponentsRegistry({
  children
}: React.PropsWithChildren) {
  // Only create stylesheet once with lazy initial state
  // x-ref: https://reactjs.org/docs/hooks-reference.html#lazy-initial-state
  const [styledComponentsStyleSheet] = useState(() => new ServerStyleSheet());
  useServerInsertedHTML(() => {
    const styles = styledComponentsStyleSheet.getStyleElement();
    styledComponentsStyleSheet.instance.clearTag();
    return styles;
  });
  if (isBrowser()) return children;
  return <StyleSheetManager sheet={styledComponentsStyleSheet.instance} data-sentry-element="StyleSheetManager" data-sentry-component="StyledComponentsRegistry" data-sentry-source-file="StyledComponentsRegistry.tsx">
			{children}
		</StyleSheetManager>;
}