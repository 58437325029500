import styled, { useTheme } from "styled-components";
import { ExternalToast, toast } from "sonner";
import { Bell20StrokedIcon } from "wint-icons";
import { BodyMediumText } from "@common/styles/typographies";
import { useAppContext } from "@common/context/AppContext";
export const useToastNotification = () => {
  const theme = useTheme();
  const {
    isDesktop
  } = useAppContext();
  const showErrorToast = (message: string, data?: ExternalToast) => {
    toast.error(<ToastContentContainer>
				<Bell20StrokedIcon fill={theme.colors.text.primary} style={{
        flexShrink: 0
      }} />
				<BodyMediumText>{message}</BodyMediumText>
			</ToastContentContainer>, {
      position: isDesktop ? "top-right" : "bottom-center",
      ...(data || {}),
      style: {
        color: theme.colors.text.primary,
        background: theme.colors.bg.feedback.error,
        borderRadius: "4px",
        border: "none",
        ...(data?.style || {})
      }
    });
  };
  const showSuccessToast = (message: string, data?: ExternalToast) => {
    toast.success(<ToastContentContainer>
				<Bell20StrokedIcon fill={theme.colors.text.primary} style={{
        flexShrink: 0
      }} />
				<BodyMediumText>{message}</BodyMediumText>
			</ToastContentContainer>, {
      position: isDesktop ? "top-right" : "bottom-center",
      ...(data || {}),
      style: {
        color: theme.colors.text.primary,
        background: theme.colors.bg.feedback.success,
        borderRadius: "4px",
        border: "none",
        ...(data?.style || {})
      }
    });
  };
  return {
    showErrorToast,
    showSuccessToast
  };
};
const ToastContentContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 12px;
`;