"use client";

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/navigation";
import { toast } from "sonner";
import { useAppContext } from "@common/context/AppContext";
import { isBrowser } from "@common/utils/browser";
import { SITE_URLS } from "@common/constants";
import CustomToast from "@features/BondDetails/components/Toast";
import { rfqInvestmentKeys } from "../constants";
export const defaultRFQPaymentContext = {
  orderReceiptSummary: null,
  // eslint-disable-next-line no-unused-vars
  setOrderReceiptSummary: data => {},
  hasApiFailed: false,
  setHasApiFailed: () => {},
  userBankAccountData: undefined,
  isUserBankAccountDataLoading: true,
  setUserBankAccountData: () => {},
  setIsUserBankAccountDataLoading: () => {}
};
const RFQPaymentContext = createContext(defaultRFQPaymentContext);
export const useRFQPaymentContext = () => useContext(RFQPaymentContext);
export const RFQPaymentContextProvider = ({
  children
}) => {
  const {
    isDesktop
  } = useAppContext();
  const [orderReceiptSummary, setOrderReceiptSummary] = useState(isBrowser() && JSON.parse(sessionStorage.getItem(rfqInvestmentKeys.cart)) != null ? JSON.parse(sessionStorage.getItem(rfqInvestmentKeys.cart)) : null);
  const [hasApiFailed, setHasApiFailed] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState();
  const [hasSeenRFQInvestmentSummaryPage, setHasSeenRFQInvestmentSummaryPage] = useState(isDesktop);

  // user bank account
  const [userBankAccountData, setUserBankAccountData] = useState();
  const [isUserBankAccountDataLoading, setIsUserBankAccountDataLoading] = useState(true);
  const [isNewCashFreeOrder, setIsNewCashFreeOrder] = useState(false);
  const [retryCashFreeSessionID, setRetryCashFreeSessionID] = useState();
  const router = useRouter();
  const resetToDefaultContext = () => {
    setOrderReceiptSummary(defaultRFQPaymentContext.orderReceiptSummary);
    setHasApiFailed(defaultRFQPaymentContext.hasApiFailed);
    setUserBankAccountData(defaultRFQPaymentContext.userBankAccountData);
    setIsUserBankAccountDataLoading(defaultRFQPaymentContext.isUserBankAccountDataLoading);
  };
  const handleClose = () => {
    try {
      const assetPath = JSON.parse(sessionStorage.getItem(rfqInvestmentKeys.cart)).page;
      sessionStorage.removeItem(rfqInvestmentKeys.cart);
      router.replace(`${assetPath}`);
    } catch (error) {
      router.replace(SITE_URLS.BONDS_LISTING);
    }
    resetToDefaultContext();
  };
  useEffect(() => {
    if (hasApiFailed && apiErrorMessage) {
      toast.custom(() => <CustomToast type="error" message={apiErrorMessage === "Unexpected end of JSON input" ? "Something went wrong!" : apiErrorMessage} />, {
        position: isDesktop ? "top-right" : "top-center"
      });
      setTimeout(() => setHasApiFailed(false), 500);
    }
  }, [hasApiFailed, apiErrorMessage]);
  const value = useMemo(() => ({
    orderReceiptSummary,
    setOrderReceiptSummary,
    //
    hasApiFailed,
    setHasApiFailed,
    //
    apiErrorMessage,
    setApiErrorMessage,
    //
    handleClose,
    //
    userBankAccountData,
    setUserBankAccountData,
    //
    isUserBankAccountDataLoading,
    setIsUserBankAccountDataLoading,
    //
    hasSeenRFQInvestmentSummaryPage,
    setHasSeenRFQInvestmentSummaryPage,
    //
    isNewCashFreeOrder,
    setIsNewCashFreeOrder,
    //
    retryCashFreeSessionID,
    setRetryCashFreeSessionID
  }), [orderReceiptSummary, hasApiFailed, apiErrorMessage, userBankAccountData, isUserBankAccountDataLoading, hasSeenRFQInvestmentSummaryPage]);
  return <RFQPaymentContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="RFQPaymentContextProvider" data-sentry-source-file="index.jsx">
			{children}
		</RFQPaymentContext.Provider>;
};