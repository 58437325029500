import styled, { css } from "styled-components";
import { ArrowRightIcon } from "wint-icons";
import { useAppContext } from "@common/context/AppContext";
import { getIsIOSDeviceByUserAgent } from "@common/utils/browser";
import { minDesktopCss } from "@common/styles/media";
import { NAVBAR_ASSETS_URL } from "../../constants";
type DownloadBannerProps = {
  title: string;
  label: string | JSX.Element;
  link: string;
  logo: string;
  rating?: string | null;
  clickId: string;
};
const DownloadBanner = ({
  title,
  label,
  link,
  logo,
  rating = null,
  clickId = ""
}: DownloadBannerProps) => {
  const {
    isDesktop
  } = useAppContext();
  const handleCTAClick = () => {
    if (!isDesktop) {
      window.open(link);
    }
  };
  const getDeviceTypeLogo = () => {
    if (getIsIOSDeviceByUserAgent(navigator.userAgent)) {
      return <Logo width={24} height={24} src={`${NAVBAR_ASSETS_URL}appstore_icon.svg`} draggable={false} alt="appStore" />;
    }
    return <Logo width={24} height={24} src={`${NAVBAR_ASSETS_URL}playstore_icon.svg`} draggable={false} alt="playstore" data-sentry-element="Logo" data-sentry-component="getDeviceTypeLogo" data-sentry-source-file="index.tsx" />;
  };
  return <CardWrapper onClick={() => handleCTAClick()} id={clickId} data-sentry-element="CardWrapper" data-sentry-component="DownloadBanner" data-sentry-source-file="index.tsx">
			<Left data-sentry-element="Left" data-sentry-source-file="index.tsx">
				<Logo width={52} height={52} src={logo} draggable={false} alt="logo" data-sentry-element="Logo" data-sentry-source-file="index.tsx" />
				<Content data-sentry-element="Content" data-sentry-source-file="index.tsx">
					<Title data-sentry-element="Title" data-sentry-source-file="index.tsx">{title}</Title>
					<Label color={rating ? "#7E7E7E" : "#1a1a1a"} data-sentry-element="Label" data-sentry-source-file="index.tsx">
						{label}{" "}
						{rating ? <>
								<Dot />
								{rating}
							</> : <IconSet>
								{isDesktop ? <>
										{" "}
										<Logo width={24} height={24} src={`${NAVBAR_ASSETS_URL}playstore_icon.svg`} draggable={false} alt="playstore" />{" "}
										<Logo width={24} height={24} src={`${NAVBAR_ASSETS_URL}apple_logo.svg`} draggable={false} alt="appStore" />
									</> : getDeviceTypeLogo()}
							</IconSet>}
					</Label>
				</Content>
			</Left>
			<Cta data-sentry-element="Cta" data-sentry-source-file="index.tsx">
				<ArrowRightIcon color="#FFF" height={16} width={16} style={{
        transform: "rotate(-45deg)"
      }} data-sentry-element="ArrowRightIcon" data-sentry-source-file="index.tsx" />
			</Cta>
		</CardWrapper>;
};
export default DownloadBanner;
const CardWrapper = styled.div`
	display: flex;
	gap: 12px;
	height: 84px;
	padding: 16px;
	justify-content: center;
	align-items: center;
	justify-content: space-between;
	border-radius: 4px;
	border: 1px solid #e5e5e5;
	&:hover {
		border: 1px solid #d9d9d9;
	}
	text-align: left;
`;
const Content = styled.div`
	display: flex;
	flex-direction: column;
`;
const Logo = styled.img``;
const Cta = styled.div`
	padding: 8px;
	border-radius: 4px;
	background: #000;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const Title = styled.div`
	color: #000;

	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;
const Label = styled.div`
	color: ${({
  color
}) => color};
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */

	display: flex;
	align-items: center;
	gap: 4px;
	margin-top: 4px;
`;
const Dot = styled.span`
	/* Other styles for the dot */
	position: relative;
	display: inline-block;
	width: 6px;
	height: 6px;
	border-radius: 50%;
	background-color: #e5e5e5;
	margin: 0 4px;
`;
const IconSet = styled.div`
	height: 24px;
	display: flex;
	& > :first-child {
		${minDesktopCss(css`
			border-right: 1px solid #f2f2f2;
		`)}
	}
`;
const Left = styled.div`
	display: flex;
	gap: 12px;
	align-items: center;
`;