"use client";

import { MotionValue, motionValue, useMotionValueEvent, useScroll } from "framer-motion";
import { Dispatch, SetStateAction, createContext, useContext, useMemo, useRef, useState } from "react";
interface ScrollContextType {
  scrollDirection?: string;
  currentScrollPosition: number;
  scrollY: MotionValue<number>;
  setDisableScrollDirection: Dispatch<SetStateAction<boolean>>;
}
interface ScrollContextPropsType {
  children: React.ReactNode;
}
const defaultContextLayout: ScrollContextType = {
  scrollDirection: undefined,
  currentScrollPosition: 0,
  scrollY: motionValue(0),
  setDisableScrollDirection: () => {}
};
const ScrollContext = createContext<ScrollContextType>(defaultContextLayout);
export const useScrollContext = () => {
  const scrollContext = useContext(ScrollContext);
  if (!scrollContext) {
    throw new Error("ScrollContext must be used inside <ScrollContextProvider /> ");
  }
  return scrollContext;
};
export const ScrollContextProvider = ({
  children
}: ScrollContextPropsType) => {
  const {
    currentScrollPosition,
    scrollY,
    scrollDirection
  } = useScrollDirection();
  const [disableScrollDirection, setDisableScrollDirection] = useState(false);
  const value = useMemo(() => ({
    scrollDirection: disableScrollDirection ? undefined : scrollDirection,
    currentScrollPosition,
    scrollY,
    setDisableScrollDirection
  }), [currentScrollPosition, disableScrollDirection, scrollDirection, scrollY]);
  return <ScrollContext.Provider value={value} data-sentry-element="unknown" data-sentry-component="ScrollContextProvider" data-sentry-source-file="ScrollContext.tsx">{children}</ScrollContext.Provider>;
};
const useScrollDirection = () => {
  const [scrollDirection, setScrollDirection] = useState<string | undefined>("");
  const [currentScrollPosition, setCurrentScrollPosition] = useState(0);
  const scrollYRef = useRef(0);
  const {
    scrollY
  } = useScroll();
  const handleScrollYChange = (latestScrollY: number) => {
    const prevScrollY = scrollYRef.current;
    if (latestScrollY > prevScrollY) {
      setScrollDirection("down");
    } else if (latestScrollY < prevScrollY) {
      setScrollDirection("up");
    }

    // Update position with requestAnimationFrame for smooth updates
    requestAnimationFrame(() => {
      setCurrentScrollPosition(latestScrollY);
      scrollYRef.current = latestScrollY;
    });
  };

  // Use animation frame for smooth scroll handling
  useMotionValueEvent(scrollY, "change", handleScrollYChange);
  return {
    scrollDirection,
    currentScrollPosition,
    scrollY
  };
};
export default ScrollContextProvider;