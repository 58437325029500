"use client";

/* eslint-disable no-nested-ternary */
import { useMemo, useState } from "react";
import { useAppContext } from "@common/context/AppContext";
import { useParams, usePathname, useRouter } from "next/navigation";
import { triggerUserLogout } from "@common/services/auth";
import { useToastNotification } from "@common/hooks/useToastNotification";
import styled, { css } from "styled-components";
import { minDesktopCss } from "@common/styles/media";
import dynamic from "next/dynamic";
import DeleteAccountModal from "@features/AccountDeletion/components/DeleteAccountModal";
import { defaultUserState } from "@common/utils/auth";
import { GridContainer } from "@common/styles";
import { useScrollContext } from "@common/context/ScrollContext";
import { isUndefinedOrNull } from "@common/utils/variable";
import { ROUTE_PARAM_SLUGS } from "@common/constants/urlParam";
import { DASHBOARD_PAGE_URL_SLUG } from "@common/constants";
import { DOWNLOAD_APP_MODAL, ALLOWED_ROUTES_BOTTOM_NAVBAR, EXCLUDED_ROUTES_FOR_BOTTOM_NAVBAR, EXCLUDED_ROUTES_FOR_NAV, EXCLUDED_ROUTES_FOR_TOP_INNERPAGE_BACK_BUTTON } from "./constants";
import BottomNavBar from "./components/BottomNavBar";
import BackMobNavigation from "./components/BackMobNavigation";
import { shouldRenderNavComponents } from "./utils";
import TopNavBar from "./components/TopNavBar";
const AuthModal = dynamic(() => import("@features/Authentication"));
const LegacyModal = dynamic(() => import("@legacy/common/components/Modal"), {
  ssr: false
});
const BottomStatCTA = dynamic(() => import("@features/Landing/components/BottomStatCTA/index"), {
  ssr: false
});
const DownloadByQrCode = dynamic(() => import("./components/DownloadByQrCode"), {
  ssr: false
});
const NavbarV2 = () => {
  const {
    userState,
    setUserState,
    isDesktop,
    showLoginModal,
    setShowLoginModal
  } = useAppContext();
  const {
    scrollDirection
  } = useScrollContext();
  const {
    showErrorToast
  } = useToastNotification();
  const router = useRouter();
  const params = useParams();
  const pathname = usePathname();
  const [showDownLoadModal, setShowDownLoadModal] = useState<boolean>(false);
  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const shouldRenderBottomNavigation: boolean = useMemo(() => {
    if (!userState.isLoggedIn) {
      // In logged out state, return true unless the pathname is in the excluded routes
      return shouldRenderNavComponents(EXCLUDED_ROUTES_FOR_BOTTOM_NAVBAR, pathname);
    }
    // In logged in state, return true only if the pathname is in the allowed routes
    return ALLOWED_ROUTES_BOTTOM_NAVBAR.includes(pathname);
  }, [pathname, userState.isLoggedIn]);
  const shouldRenderNavigationButton: boolean = useMemo(() => {
    if (userState.isLoggedIn) {
      const slugRouteCondition = !isUndefinedOrNull(params[ROUTE_PARAM_SLUGS.DASHBOARD.DETAILS_PAGE]);
      return shouldRenderNavComponents(EXCLUDED_ROUTES_FOR_TOP_INNERPAGE_BACK_BUTTON, pathname) || slugRouteCondition;
    }
    return false;
  }, [params, pathname, userState.isLoggedIn]);
  const handleShowDownLoadModal = (): void => {
    setShowDownLoadModal(!showDownLoadModal);
  };
  const handleLogout = (): void => {
    try {
      triggerUserLogout(router).then(() => {
        setUserState(defaultUserState);
      });
    } catch (error) {
      showErrorToast(error instanceof Error ? error.message : "Failed to logout!");
    }
  };
  const openLoginModal = (): void => {
    setShowLoginModal(true);
  };

  // Function to check if a given URL is currently active in the browser.
  const checkIsUrlActive = (url: string): boolean => {
    // dashboard is multiple urls for multiple products
    // so we check if its a dashboard url and if the pathname includes portfolio
    if (url?.includes(DASHBOARD_PAGE_URL_SLUG) && pathname.includes(DASHBOARD_PAGE_URL_SLUG)) {
      return true;
    }

    // but rest should be direct map
    if (url === pathname) {
      return true;
    }
    return false;
  };
  return <StyledNavbarWrapper id="navbar" $scrollDirection={scrollDirection} $isSticky={!!userState.isLoggedIn} data-sentry-element="StyledNavbarWrapper" data-sentry-component="NavbarV2" data-sentry-source-file="index.tsx">
			<GridContainer data-sentry-element="GridContainer" data-sentry-source-file="index.tsx">
				{shouldRenderNavComponents(EXCLUDED_ROUTES_FOR_NAV, pathname) && pathname !== "/" ? !isDesktop ? <>
							{shouldRenderNavigationButton && <BackMobNavigation />}
							{shouldRenderBottomNavigation && !showLoginModal && userState?.isLoggedIn && <BottomNavBar openLoginModal={openLoginModal} handleLogout={handleLogout} checkIsUrlActive={checkIsUrlActive} />}

							{shouldRenderBottomNavigation && !showLoginModal && !userState?.isLoggedIn && <BottomStatCTA />}
						</> : <TopNavBar handleLogin={openLoginModal} handleLogout={handleLogout} handleShowDownLoadModal={handleShowDownLoadModal} checkIsUrlActive={checkIsUrlActive} /> : null}

				<AuthModal showLoginModal={showLoginModal} closeOnEsc data-sentry-element="AuthModal" data-sentry-source-file="index.tsx" />

				{showDownLoadModal && <LegacyModal show={showDownLoadModal} onClose={handleShowDownLoadModal} padding="40px" borderRadius="4px">
						<DownloadByQrCode data={DOWNLOAD_APP_MODAL} />
					</LegacyModal>}
				<DeleteAccountModal isAccountDeleted show={isDeletionModalOpen} onClose={() => {
        setIsDeletionModalOpen(false);
      }} onSignupWithOtherAccountClick={() => setShowLoginModal(true)} data-sentry-element="DeleteAccountModal" data-sentry-source-file="index.tsx" />
			</GridContainer>
		</StyledNavbarWrapper>;
};
export default NavbarV2;
const StyledNavbarWrapper = styled.div<{
  $scrollDirection?: string;
  $isSticky: boolean;
}>`
	z-index: 999999;
	width: 100%;
	position: ${({
  $isSticky
}) => $isSticky ? "sticky" : "relative"};
	top: 0px;
	${minDesktopCss(css<{
  $scrollDirection?: string;
}>`
		top: ${({
  $scrollDirection
}) => $scrollDirection === "down" ? "-64px" : "0"};
		transition: top 0.3s ease-in-out;
		position: sticky;
	`)}
`;