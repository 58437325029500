"use client";

import { createGlobalStyle, css } from "styled-components";
const globalStyle = css`
	* {
		box-sizing: border-box;
		word-wrap: break-word;
		-webkit-tap-highlight-color: transparent;
		margin: 0;
		padding: 0;
	}

	html,
	body {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		scrollbar-gutter: stable;
		margin: 0px;
	}

	button {
		border: none;
		background: none;
		outline: none;
		padding: 0;
		cursor: pointer;
		&:focus {
			outline: 0 !important;
		}
	}

	img[src*="base64\\,"] {
		image-rendering: -moz-crisp-edges;
		image-rendering: crisp-edges;
	}
`;
export default createGlobalStyle`
    ${globalStyle}
`;