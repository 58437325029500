import styled, { css, useTheme } from "styled-components";
import { Bell20StrokedIcon } from "wint-icons";
import { BodyMediumText } from "@common/styles/typographies";
import { minDesktopCss } from "@common/styles/media";
type CustomToastProps = {
  type?: string;
  message: string;
};
const CustomToast: React.FC<CustomToastProps> = ({
  type,
  message
}) => {
  const theme = useTheme();
  const getColor = () => {
    if (type === "error") {
      return theme?.colors.text.feedback.error;
    }
    if (type === "warning") {
      return theme?.colors.text.primary;
    }
    return theme?.colors.text.feedback.success;
  };
  return <InfoToast $type={type} data-sentry-element="InfoToast" data-sentry-component="CustomToast" data-sentry-source-file="index.tsx">
			<BellIcon fill={getColor()} data-sentry-element="BellIcon" data-sentry-source-file="index.tsx" />
			<BodyMediumText $webColor={getColor()} $mobColor={getColor()} data-sentry-element="BodyMediumText" data-sentry-source-file="index.tsx">
				{message}
			</BodyMediumText>
		</InfoToast>;
};
export default CustomToast;
const InfoToast = styled.div<{
  $type?: string;
}>`
	padding: 12px 16px;
	display: flex;
	align-items: center;
	gap: 4px;
	border: 1px solid #8bf1a7;
	background-color: ${({
  theme
}) => theme.colors.bg.feedbackCommon.success};
	border-radius: 4px;
	width: 100%;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	${minDesktopCss(css`
		min-width: 350px;
		max-width: 450px;
	`)}

	${({
  $type
}) => $type === "error" && css`
			border: 1px solid #ffc7c5;
			background-color: ${({
  theme
}) => theme.colors.bg.feedbackCommon.error};
		`}

		${({
  $type
}) => $type === "warning" && css`
			border: none;
			background-color: ${({
  theme
}) => theme.colors.bg.feedback.warning};
		`}
`;
const BellIcon = styled(Bell20StrokedIcon)`
	min-width: 20px;
	min-height: 20px;
`;