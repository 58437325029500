"use client";

import { useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import { ArrowRightIcon } from "wint-icons";
import { GridItemContainer } from "@common/styles";
import { SITE_URLS } from "@common/constants";
import { isBrowser } from "@common/utils/browser";
import { useAppContext } from "@common/context/AppContext";
import { getInitialsFromName } from "@common/utils/string";
import { getAppDownloadLink, getTopNavBarItems, handleReferAndEarnClick } from "../../utils";
import DownloadBanner from "../DownloadBanner";
import { NAV_CTA_IDS, DOWNLOAD_APP_BANNER_DATA, NAVBAR_ASSETS_URL } from "../../constants";
import NavbarButton from "../NavbarButton";
import { DropdownSideBarItem, TopNavbarActions } from "../../types";

/**
 *  @param {*} handleLogin: Function to handle user login.
 *  @param {*} handleLogout: Function to handle user logout.
 *  @param {*} handleShowDownLoadModal: Function to handle showing the download modal.
 *  @param {*} checkIsUrlActive: Function that returns true if current Url and matched with navigation url for rendering active state.
 *  @returns navbar version 2 for rendering
 */

type TopNavBarProps = {
  handleLogin: () => void;
  handleLogout: () => void;
  handleShowDownLoadModal: () => void;
  checkIsUrlActive: (url: string) => boolean;
};
const TopNavBar = ({
  handleLogin,
  handleLogout,
  handleShowDownLoadModal,
  checkIsUrlActive
}: TopNavBarProps) => {
  const {
    isIOSDevice,
    userState
  } = useAppContext();
  const handleDropdownToggle = (close = false) => {
    const dropdown = document?.getElementById("dropdown");
    if (dropdown) {
      if (dropdown?.style.display === "block" || close) {
        dropdown.style.animation = "dropdownAnimationReverse 0.3s ease-in-out forwards";
        setTimeout(() => {
          dropdown.style.display = "none";
        }, 300); // Adjust the timeout to match the animation duration
      } else {
        dropdown.style.display = "block";
        dropdown.style.animation = "dropdownAnimation 0.3s ease-in-out forwards";
      }
    }
  };
  const actions: TopNavbarActions = useMemo(() => {
    return {
      dropdown: () => handleDropdownToggle(),
      login: handleLogin,
      logOut: handleLogout,
      referAndClick: () => handleReferAndEarnClick()
    };
  }, [handleLogin, handleLogout]);
  const navigationItems = useMemo(() => {
    return getTopNavBarItems(actions, getInitialsFromName(userState.userName), userState);
  }, [actions, userState]);
  const handleDropdownItemClick = (dropdownItem: DropdownSideBarItem) => {
    if (dropdownItem.action) {
      dropdownItem.action();
    } else if (isBrowser() && dropdownItem.link) {
      window.location.href = dropdownItem.link;
    }
  };
  useEffect(() => {
    // ticking variable to prevent scroll event handler from being invoked too often, which could cause performance issues.
    let ticking = false;
    const handleScroll = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          handleDropdownToggle(true);
          ticking = false;
        });
        ticking = true;
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // handle close on click of body and not key elements
  useEffect(() => {
    const handleBodyClick = (event: {
      target: any;
    }) => {
      let targetElement = event.target;
      while (targetElement) {
        if (["modal", "dropdown", "NAV_DROPDOWN"].includes(targetElement.id)) {
          // Click occurred inside the dropdown, do not handle it
          return;
        }
        targetElement = targetElement.parentElement;
      }
      // Handling body click event here
      handleDropdownToggle(true);
    };
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);
  return <NavbarContainer as="nav" $useGridPadding data-sentry-element="NavbarContainer" data-sentry-component="TopNavBar" data-sentry-source-file="index.tsx">
			{/* left lists */}
			<NavList data-sentry-element="NavList" data-sentry-source-file="index.tsx">
				{navigationItems?.left.filter(items => items.isVisible).map((item, index) => {
        const isActiveUrl = checkIsUrlActive(item.link as string);
        return <NavItem key={index} className="left" id={item?.id} $isActive={!(isActiveUrl || item.icon)}>
								<NavLink href={isActiveUrl ? undefined : item.link} $isActive={isActiveUrl}>
									{item?.isLottieIcon && item?.icon ? <>
											<LogoMask />
											<iframe title="wint icon" style={{
                willChange: "transform"
              }} src={item?.icon} height="64px" width="172px" frameBorder="0" />
										</> : item?.icon && <item.icon className="logo" width={116} height={32} />}
									{item.label}
								</NavLink>
							</NavItem>;
      })}
			</NavList>
			{/* right sections, has buttons and dropdown */}
			<NavList data-sentry-element="NavList" data-sentry-source-file="index.tsx">
				{navigationItems?.right.filter(items => items.isVisible).map((item, index) => <NavItem key={index} id={item?.id} $isActive={false}>
							<NavLink id={item?.id} onClick={e => {
          if (item.action) {
            item.action();
            e.stopPropagation();
          }
        }} href={item.link}>
								<NavbarButton item={item} checkIsUrlActive={checkIsUrlActive} />

								{item.dropdown && <Dropdown id="dropdown" onClick={e => {
            e.stopPropagation();
            e.preventDefault();
          }}>
										<Header onClick={() => {
              window.location.href = SITE_URLS.PROFILE;
            }} id={NAV_CTA_IDS.your_profile}>
											<Details>
												<Name title={userState.userName}>
													{userState.userName}
												</Name>
												<Profile className="acc-details">
													{" "}
													<div>Account Details </div>{" "}
													<StyledArrowRightIcon height={12} width={12} style={{
                    transform: "rotate(-45deg)"
                  }} />
												</Profile>
											</Details>
											<UserIcon>
												{getInitialsFromName(userState.userName)}
											</UserIcon>
										</Header>
										{item.dropdown?.filter(items => items.isVisible).map((dropdownItem: DropdownSideBarItem, idx) => <ItemWrapper id={dropdownItem.id} key={idx} onClick={e => {
              handleDropdownItemClick(dropdownItem);
              e.stopPropagation();
            }}>
													<DropDownIcon alt={`icon_${idx}`} src={dropdownItem.icon} />
													<DropdownItem className="drop-item" $isLast={idx === (item.dropdown?.length ?? 0) - 1}>
														{dropdownItem.label}
													</DropdownItem>
												</ItemWrapper>)}
										<CardWrapper>
											{/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events  */}
											<span onClick={e => {
                handleShowDownLoadModal();
                e.stopPropagation();
              }}>
												<DownloadBanner clickId="NAV-DOWNLOAD_APP" title={DOWNLOAD_APP_BANNER_DATA.title} label={DOWNLOAD_APP_BANNER_DATA.label} logo={`${NAVBAR_ASSETS_URL}wint_icon.svg`} link={getAppDownloadLink(isIOSDevice)} />
											</span>
										</CardWrapper>
									</Dropdown>}
							</NavLink>
						</NavItem>)}
			</NavList>
		</NavbarContainer>;
};
export default TopNavBar;
const NavbarContainer = styled(GridItemContainer)`
	height: 66px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background: white;
	position: sticky;
	top: 0;
	z-index: 5;
	width: 100%;
	border-bottom: 1px solid;
	border-image-slice: 1;
	border-width: 5px;
	@media only screen and (max-width: 600px) {
		display: none;
	}
	.dashboard_icon {
		margin-right: 8px;
	}
	.lottie-file {
		margin-right: 0.5rem;
		height: 20px;
		width: 20px;

		flex-shrink: 0;
	}
`;
const NavList = styled.ul`
	list-style-type: none;

	display: flex;
	height: 100%;
	gap: 12px;

	.left:not(:first-child) {
		width: 70px;
	}
`;
interface NavbarItemProps {
  $isActive?: boolean;
}
const NavItem = styled.li<NavbarItemProps>`
	position: relative;

	height: 100%;
	display: flex;

	align-items: center;
	cursor: pointer;

	a::before,
	a::after {
		content: "";
		display: block;
		height: 2px;
		background-color: #3de86d;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		width: 0%;
		transition: width 0.3s ease-in-out; /* Only transition width */
		border-radius: 1px;
	}

	a::after {
		bottom: 50%;
	}

	${({
  $isActive
}) => $isActive && `
      a:hover::before,
      a:hover::after {
        width: 100%;
      }
    `}
`;
const NavLink = styled.a<NavbarItemProps>`
	text-align: center;

	text-decoration: none;
	width: 100%;

	color: ${({
  $isActive
}) => $isActive ? "#000" : "#7E7E7E"};

	font-weight: ${({
  $isActive
}) => $isActive ? "600" : "400"};

	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;

	line-height: 20px;

	.logo {
		margin-right: 12px;
	}
`;
const Dropdown = styled.div`
	display: none;
	position: absolute;
	text-align: left;
	right: 0;

	color: black;

	width: 352px;
	height: auto;

	border-radius: 4px;
	border: 1px solid #cbcbcb;
	background: #fff;

	animation: none; /* Initially no animation */

	@keyframes dropdownAnimation {
		0% {
			opacity: 0;
			margin-top: 0;
		}
		100% {
			opacity: 1;
			margin-top: 1rem;
			transition: transform 0.3s ease-in;
		}
	}

	@keyframes dropdownAnimationReverse {
		0% {
			opacity: 1;
			margin-top: 1rem;
		}
		100% {
			transition: transform 0.3s ease-out;
			margin-top: 0;
			opacity: 0;
		}
	}
`;
const DropdownItem = styled.div<{
  $isLast: boolean;
}>`
	position: relative;
	${({
  $isLast
}) => !$isLast && "border-bottom: 1px solid #e5e5e5;"}
	width: 100%;
	color: #000;
	padding: 24px 0;
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	height: 72px;
`;
const Header = styled.div`
	width: 100%;
	height: 100px;
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	border-bottom: 1px solid #f2f2f2;
	background: #fafafa;
	padding: 24px;
	display: flex;
	justify-content: space-between;
	text-decoration: none;

	.acc-details {
		color: #7e7e7e;
	}

	&:hover {
		.acc-details {
			color: #000;
		}
	}
`;
const Details = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: baseline;
`;
const Name = styled.div`
	color: #000;

	/* Web/Heading/Sans-serif/Medium (20) */

	font-family: var(--font-inter);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 32px; /* 160% */

	max-width: 18ch;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
`;
const Profile = styled(motion.div).attrs({
  whileTap: {
    scale: 0.9
  }
})`
	font-family: var(--font-inter);
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px; /* 142.857% */
	display: flex;
	align-items: center;
	gap: 4px;
`;
const StyledArrowRightIcon = styled(ArrowRightIcon)`
	color: inherit;
`;
const UserIcon = styled.div`
	border-radius: 4px;
	background: #172557;
	width: 52px;
	height: 52px;
	color: #fff;

	font-family: var(--font-inter);
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	display: flex;
	justify-content: center;
	align-items: center;
`;
const ItemWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	gap: 12px;
	height: 72px;

	&:hover {
		background: #fafafa;
		z-index: 2;
		width: 100%;
	}

	a {
		width: 100%;
	}
`;
const DropDownIcon = styled.img`
	padding: 24px 0 24px 24px;
`;
const CardWrapper = styled.div`
	padding: 56px 24px 24px;
	cursor: initial;
	span {
		cursor: pointer;
	}
`;
const LogoMask = styled.div`
	width: 100%;
	height: 100%;
	position: absolute;
	z-index: 1;
`;