export const AFFILIATE_URL_PARAM_ID = "affId"

export const REFERRAL_URL_PARAM_ID = "refId"

export const CAMPAIGN_URL_PARAM_ID = "campaign"

export const ROUTE_PARAM_SLUGS = {
	DASHBOARD: {
		PRODUCT_PAGE: "product_name",
		DETAILS_PAGE: "investment_key",
	},
}
