import { useAppContext } from "@common/context/AppContext";
import LegacyButton from "@legacy/common/components/Button";
import { useState } from "react";
import LottiePlayerLight from "react-lottie-player/dist/LottiePlayerLight";
import styled from "styled-components";
import { ChevronDownIcon } from "wint-icons";
import { NavbarItem } from "../../types";
const LegacyButtonTyped: any = LegacyButton;
type NavbarButtonProps = {
  item: NavbarItem;
  checkIsUrlActive: (url: string) => boolean;
};
const NavbarButton = ({
  item,
  checkIsUrlActive
}: NavbarButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const {
    userState
  } = useAppContext();
  switch (item.buttonType) {
    case "transparent":
      return <span onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)} id={item?.id}>
					<LegacyButtonTyped width="145px" id={item?.id} fontWeight={checkIsUrlActive(item.link as string)} startIcon={item.icon ? <LottiePlayerLight className="lottie-file" key={`${isHovered}`} loop={false} play={isHovered} animationData={item.icon} /> : null} variant="no-background" text={item.label} contentPadding="8px 16px" />
				</span>;
    case "primary":
      return <ButtonWrapper id={item?.id}>
					<LegacyButtonTyped width={userState?.isLoggedIn ? "32px" : "121px"} height={userState?.isLoggedIn ? "32px" : "36px"} id={item?.id} variant="black" text={item.label} fontSize="14px" textColor="white" contentPadding={userState?.isLoggedIn ? "8px" : "8px 16px"} />
					{item.icon && <ChevronDownIcon id={item?.id} width="16px" height="16px" color="rgba(26, 26, 26, 0.4)" />}
				</ButtonWrapper>;
    default:
      return null;
  }
};
export default NavbarButton;
const ButtonWrapper = styled.div`
	display: flex;
	gap: 4px;
	align-items: center;
	justify-content: center;
`;