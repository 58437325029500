/* eslint-disable react/prop-types */

import { tabletCss } from "@common/styles/media";
import styled, { css } from "styled-components";
const LegacyButton = ({
  text,
  variant,
  size,
  startIcon,
  rounded,
  ml = 0,
  mt = 0,
  marginRight = 0,
  marginBottom = 0,
  onClick,
  disabled,
  endIcon,
  id,
  width,
  height,
  justifyContent,
  fontSize,
  fontWeight = 500,
  contentPadding,
  isLoading,
  bgColor,
  textColor,
  type
}) => {
  return <ButtonElement onClick={onClick} rounded={rounded} $variant={variant} size={size} $ml={ml} $mt={mt} $marginRight={marginRight} $marginBottom={marginBottom} disabled={disabled} isLoading={isLoading} id={id} width={width} height={height} fontSize={fontSize} $fontWeight={fontWeight} bgColor={bgColor} $textColor={textColor} type={type} data-sentry-element="ButtonElement" data-sentry-component="LegacyButton" data-sentry-source-file="index.jsx">
			<Content justifyContent={justifyContent} id={id} $contentPadding={contentPadding} data-sentry-element="Content" data-sentry-source-file="index.jsx">
				{isLoading ? <Loader /> : <>
						{startIcon || null}
						{text}
						{endIcon || null}
					</>}
			</Content>
		</ButtonElement>;
};
const ButtonElement = styled.button`
	display: flex;
	background-color: ${props => props.bgColor ? props.bgColor : "rgb(83, 187, 83)"};

	border: none;
	border-radius: ${props => props.rounded ? "48px" : "4px"};
	color: white;

	${tabletCss(css`
		color: ${props => props.$textColor ? props.$textColor : "black"};
	`)}

	cursor: pointer;
	white-space: pre;
	font-size: ${props => props.fontSize ? props.fontSize : "0.8rem"};
	font-weight: ${props => props.$fontWeight ? props.$fontWeight : ""} !important;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.2s ease-in-out;
	align-items: center;
	justify-content: center;
	margin-left: ${props => props.$ml ? props.$ml : 0}rem;
	margin-top: ${props => props.$mt ? props.$mt : 0}rem;
	margin-bottom: ${props => props.$marginBottom ? props.marginBottom : 0}rem;
	margin-right: ${props => props.$marginRight ? props.marginRight : 0}rem;
	width: ${props => props.width ? props.width : ""};
	height: ${props => props.height ? props.height : ""};
	${({
  $variant
}) => $variant === "outlined" && css`
			background-color: transparent;
			color: #356935;
			border: 1px solid #356935;
			${tabletCss(css`
				color: #fff;
				border: 1px solid rgba(255, 255, 255, 0.7);
			`)}
		`}

	${({
  $variant
}) => $variant === "black" && css`
			background-color: #000000;
			font-family: var(--font-inter);
			font-style: normal;
			font-weight: 500;
			line-height: 20px; /* 142.857% */
			color: #fff;
			&:hover {
				background-color: #242424;
			}
		`}
    
    ${({
  $variant
}) => $variant === "outlined-black" && css`
			background-color: transparent;
			color: #101010;
			border: 1px solid #101010;

			${tabletCss(css`
				color: #fff;
				border: 1px solid rgba(0, 0, 0, 0.5);
			`)}
		`}

    ${({
  $variant
}) => $variant === "outlined-white" && css`
			background-color: transparent;
			color: white;
			border: 1px solid white;

			${tabletCss(css`
				color: #fff;
				border: 1px solid rgba(0, 0, 0, 0.5);
			`)}
		`}
  ${({
  $variant
}) => $variant === "danger" && css`
			background-color: #c02e2e;
			color: white;
		`}
    ${({
  $variant
}) => $variant === "no-background" && css`
			background-color: transparent;
			border: 0;

			color: #000000;

			text-align: center;

			/* Web/Nav/Regular (16) */
			font-family: var(--font-inter);
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: 20px; /* 125% */
			&:hover {
				background-color: #fafafa;
			}
		`}

    ${({
  $variant
}) => $variant === "no-border" && css`
			background-color: white;
			border: 1px solid rgba(0, 0, 0, 0.5);

			color: #000000;
		`}
    ${({
  size
}) => size === "small" && css`
			font-size: 0.8rem;
			padding: 0 1rem;
		`}
    ${({
  size
}) => size === "large" && css`
			font-size: 1rem;
		`}
    ${({
  disabled
}) => disabled && css`
			opacity: 0.5;
			cursor: unset;
		`}
    ${({
  isLoading
}) => isLoading && css`
			opacity: 0.5;
			cursor: unset;
			font-size: 1rem;
		`};
`;
const Content = styled.div`
	display: flex;
	padding: ${props => props.$contentPadding ? props.$contentPadding : "0.8rem 1rem"};
	justify-content: ${props => props.justifyContent ? props.justifyContent : "center"};
	align-items: center;
	width: 100%;
`;
const Loader = styled.div`
	width: 20px;
	height: 20px;
	border: 3px solid #fff;
	border-bottom-color: transparent;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	margin-left: 0.5rem;

	@keyframes rotation {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

	${tabletCss(css`
		border: 3px solid #000;
		border-bottom-color: white;
	`)}
`;
export default LegacyButton;