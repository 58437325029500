import styled from "styled-components";
import { CrossIcon } from "wint-icons";
import { AnimatePresence, motion } from "framer-motion";
import { HideScrollBars } from "@common/styles";
import Portal from "@common/components/Portal";
import useLockBodyScroll from "@common/hooks/useLockBodyScroll";
import { getWintDomainUrl } from "@common/utils/env";
import { handleMixpanelEvent } from "@common/utils/analytics";
import { usePathname } from "next/navigation";
type BottomSheetProps = {
  show: boolean;
  onClose?: () => void;
  title?: React.ReactNode;
  content?: React.ReactNode;
  hideCloseIcon?: boolean;
  closeIconClickId?: string;
};
const slideInBottom = {
  hidden: {
    y: "100vh",
    opacity: 0
  },
  visible: {
    y: "0",
    opacity: 1,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    y: "100vh",
    opacity: 0,
    transition: {
      duration: 0.3
    }
  }
};
const BottomSheet: React.FC<BottomSheetProps> = ({
  show,
  onClose,
  title,
  content,
  hideCloseIcon,
  closeIconClickId
}) => {
  useLockBodyScroll({
    isLocked: show
  });
  const pathName = usePathname();
  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    handleMixpanelEvent("Button Click", {
      "Click ID": closeIconClickId,
      "Page Path": pathName,
      "Page Domain": getWintDomainUrl()
    });
  };
  return <Portal data-sentry-element="Portal" data-sentry-component="BottomSheet" data-sentry-source-file="BottomSheet.tsx">
			<AnimatePresence mode="wait" onExitComplete={() => null} data-sentry-element="AnimatePresence" data-sentry-source-file="BottomSheet.tsx">
				{show ? <Overlay initial={{
        opacity: 0
      }} animate={{
        opacity: 1
      }} exit={{
        opacity: 0
      }} transition={{
        duration: 0.3
      }} onClick={onClose}>
						<Container variants={slideInBottom} initial="hidden" animate="visible" exit="exit" show={show} onClick={(e: React.MouseEvent) => e.stopPropagation()}>
							{!hideCloseIcon ? <Cross onClick={handleClose} id={closeIconClickId} /> : null}
							<ContentWrapper>
								<Header>{title || null}</Header>
								<Content>{content || null}</Content>
							</ContentWrapper>
						</Container>
					</Overlay> : null}
			</AnimatePresence>
		</Portal>;
};
export default BottomSheet;
const Overlay = styled(motion.div)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({
  theme
}) => theme.colors.bg.overlay.backdrop};
	z-index: 99999999;
`;
const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
	width: 100%;
	background: ${({
  theme
}) => theme.colors.bg.card.default};
	border-radius: 16px 16px 0rem 0rem;
	min-height: 180px;
	max-height: 75vh;
	height: fit-content;

	@supports (max-height: 75dvh) {
		max-height: 75dvh;
	}
`;
const Container = styled(motion.div)<{
  show?: boolean;
}>`
	position: absolute;
	bottom: 0;
	width: 100%;
`;
const Cross = styled(CrossIcon)`
	position: absolute;
	top: -2.25rem;
	right: 1rem;
	color: ${({
  theme
}) => theme.colors.text.textInverse.primary};
	width: 24px;
	height: 24px;
`;
const Header = styled.div`
	padding: 2rem 1.5rem 0rem;
	width: 100%;
`;
const Content = styled.div`
	padding: 0rem 1.5rem 2rem 1.5rem;
	overflow-y: scroll;
	width: 100%;

	${HideScrollBars}
`;